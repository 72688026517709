<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('消费记录')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-main">
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div v-for="(item, index) in list" :key="index" class="bs-panel wt-card">
                        <div class="wt-items">
                            <div class="label">{{$t('金额')}}</div>
                            <div class="value text-red">{{parseFloat(item.money * -1).toFixed(2)}}</div>
                        </div>
                        <div class="wt-items">
                            <div class="label">{{$t('时间')}}</div>
                            <div class="time">{{item.created_at}}</div>
                        </div>
                        <div class="btn cons-tag btn-blue" >{{$t(item.title)}}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get(this.$api_url.cost_list + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        console.log(this.list)
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        }

    }
</script>
<style scoped="scoped">
.cons-tag{
    margin-top: 10px;
}
.inc-info{
    font-weight: bold;
    font-size: 16px;
}
.wt-items{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.wt-items .label{
    margin-right: 5px;
    color: #8c93b4;
}
.inc-tabs .van-tabs__nav{
    background-color: transparent;
}
.inc-tabs .van-tab{
    padding: 0;
}
.inc-tabs .van-tabs__line{
    display: none;
}
.inc-tabs .van-tab__text{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 0 15px;
    border-radius: 6px;
}
.wt-card{
    margin-bottom: 15px;
    font-size: 14px;
    padding: 15px;
}
.wt-card-head{
    font-size: 16px;
    font-weight: bold;
}
.wt-intro{
    margin-right: 5px;
}
</style>